











import { defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import VueI18n from 'vue-i18n/types';

export default defineComponent({
  name: 'CustomTextarea',
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    label: {
      type: String as PropType<VueI18n.TranslateResult>,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    rows: {
      type: Number,
      default: 3
    }
  }
});
