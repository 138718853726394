































































































































































































import { defineComponent, ref, computed, onMounted } from '@nuxtjs/composition-api';
import type { ComputedRef, Ref } from '@nuxtjs/composition-api';
import SfSelectOption from '@storefront-ui/vue/src/components/molecules/SfSelect/_internal/SfSelectOption.vue';
import { SfModal } from '@storefront-ui/vue';
import PrimaryInput from '~/components/atoms/Inputs/PrimaryInput/PrimaryInput.vue';
import {
  SALESFORCE_WEBFORM_FIELD,
  SALESFORCE_FORM_SUBMIT_URL,
  SALESFORCE_WEBFORM_REASONS,
  SALESFORCE_FORM_INDICATOR
} from '~/constants/salesforce';
import Select from '~/components/atoms/Inputs/Select/Select.vue';
import CustomTextarea from '~/components/atoms/Inputs/CustomTextarea/CustomTextarea.vue';
import Button from '~/components/atoms/Buttons/Button/Button.vue';
import { useI18n } from '~/composables';
import { SalesforceWebformReason, SalesforceWebformSubreason } from '~/types/salesforce';
import { salesforceWebformLocales } from '~/i18n';

export default defineComponent({
  name: 'SaleforceWebform',
  components: {
    SfModal,
    PrimaryInput,
    Select,
    SfSelectOption,
    CustomTextarea,
    Button
  },
  props: {
    onClose: {
      type: Function,
      required: true
    }
  },
  setup () {
    const { countryLabel, language, languageAndCountry } = useI18n();

    const selectedReasonIndicator: Ref<ValueOf<typeof SALESFORCE_FORM_INDICATOR>> =
      ref(SALESFORCE_FORM_INDICATOR.SALESFORCE_REASON_NONE);
    const selectedSubreasonIndicator: Ref<ValueOf<typeof SALESFORCE_FORM_INDICATOR>> =
      ref(SALESFORCE_FORM_INDICATOR.SALESFORCE_REASON_NONE);
    const selectedReason: Ref<SalesforceWebformReason | null> = ref(null);
    const selectedSubreason: Ref<SalesforceWebformSubreason | null> = ref(null);
    const webformLocale: Ref<ValueOf<typeof salesforceWebformLocales>> = ref(salesforceWebformLocales[language.value]);
    const webformDescription: Ref<String> = ref('');
    const contactName: Ref<String> = ref('');
    const companyName: Ref<String> = ref('');
    const customerEmail: Ref<String> = ref('');
    const formSubject: Ref<String> = ref('');
    const orderNumber: Ref<String> = ref('');
    const productNumber: Ref<String> = ref('');
    const invoiceNumber: Ref<String> = ref('');
    const currentUrl: Ref<String> = ref('');

    const selectedReasonSubreasons: ComputedRef<SalesforceWebformSubreason[]> = computed(() => {
      return SALESFORCE_WEBFORM_REASONS.find(
        reason => reason.indicator === selectedReasonIndicator.value
      )?.subreasons || [];
    });

    const updateSelectedReasonIndicator = (selectedValue: ValueOf<typeof SALESFORCE_FORM_INDICATOR>) => {
      selectedReason.value = SALESFORCE_WEBFORM_REASONS.find(reason =>
        SALESFORCE_WEBFORM_FIELD[reason.indicator] === selectedValue
      );
      selectedReasonIndicator.value = selectedReason ? selectedReason.value?.indicator : '';
    };

    const updateSelectedSubreasonIndicator = (selectedValue: ValueOf<typeof SALESFORCE_FORM_INDICATOR>) => {
      selectedSubreason.value = selectedReason.value.subreasons?.find(subreason =>
        SALESFORCE_WEBFORM_FIELD[subreason.indicator] === selectedValue
      );
      selectedSubreasonIndicator.value = selectedSubreason ? selectedSubreason.value?.indicator : '';
    };

    const getFieldVisibility = (field: string): ComputedRef<boolean> => {
      return computed(() => {
        return selectedReasonSubreasons.value.find(subreason =>
          subreason.indicator === selectedSubreason.value?.indicator
        )?.[field] || false;
      });
    };

    const shouldShowProductNumberField = getFieldVisibility('needsProductNumber');
    const shouldShowInvoiceNumberField = getFieldVisibility('needsInvoiceNumber');
    const shouldShowOrderNumberField = getFieldVisibility('needsOrderNumber');

    onMounted(() => {
      currentUrl.value = typeof window === 'object' ? window.location.href : '';
    });

    return {
      SALESFORCE_FORM_SUBMIT_URL,
      SALESFORCE_WEBFORM_REASONS,
      SALESFORCE_WEBFORM_FIELD,
      selectedReasonSubreasons,
      updateSelectedReasonIndicator,
      updateSelectedSubreasonIndicator,
      selectedReasonIndicator,
      selectedSubreasonIndicator,
      selectedReason,
      selectedSubreason,
      countryLabel,
      webformLocale,
      shouldShowProductNumberField,
      shouldShowOrderNumberField,
      shouldShowInvoiceNumberField,
      webformDescription,
      contactName,
      companyName,
      customerEmail,
      formSubject,
      currentUrl,
      orderNumber,
      productNumber,
      invoiceNumber,
      language,
      languageAndCountry
    };
  }
});
